// @ts-nocheck
import { CFormInput, CSpinner, CFormSelect, CFormCheck } from "@coreui/react";
import React, { FormEvent, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";

import BoxWrapper from "../../../components/BoxWrapper";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import RichTextEditor from "../../../components/RichTextEditor";
import { slugify, createZoneAwareDate } from "../../../helpers/general";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import { useAuth } from "../../../hooks/useAuth";
import { verifyFileList, toDateInputValue } from "../../../helpers/general";
import apiService from "../../../service/apiService";
import TimeSelectInput from "../../../components/inputs/TimeSelectInput";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../helpers/enums";
import FileUploader from "../../../components/FileUploader";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

interface CreateEventProps {
  name: string;
  date: string;
  picture: File;
  startingHour: string;
  endingHour: string;
  description: string;
  address: string;
  typeId: string;
  cityId: string;
  email: string;
  website: string;
  facebookURL: string;
  instagramURL: string;
}

export default function NewEvent({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const params = useParams();
  const { isAdmin } = useAuth();
  const [isExternalBooking, setIsExternalBooking] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [images, setImages] = useState({
    image: ""
  });

  const eventDatesSchema = yup.object({
    date: yup
      .date()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire")
      .transform((_, val) => {
        return new Date(val);
      })
  });

  const schema = yup.object({
    name: yup.string().required("Ce champ est obligatoire"),
    placeId: yup.object().nullable(),
    date: yup
      .date()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire")
      .transform((_, val) => {
        return new Date(val);
      }),
    picture: yup.mixed().required("Ce champ est obligatoire"),
    startingHour: yup.string().required("Ce champ est obligatoire"),
    endingHour: yup.string().required("Ce champ est obligatoire"),
    hoursToLeaveOpen: yup.string().required("Ce champ est obligatoire"),
    cityId: yup
      .object()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    address: yup.string().required("Ce champ est obligatoire"),
    typeId: yup
      .object()
      .required("Ce champ est obligatoire")
      .typeError("Ce champ est obligatoire"),
    email: yup
      .string()
      .email("Veuillez entrer un email valide")
      .required("Ce champ est obligatoire"),
    phone: yup.string().required("Ce champ est obligatoire"),
    website: yup
      .string()
      .url("Veuillez entrer un URL valide")
      .nullable(),
    facebookUrl: yup
      .string()
      .url("Veuillez entrer un URL valide")
      .nullable(),
    instagramUrl: yup
      .string()
      .url("Veuillez entrer un URL valide")
      .nullable(),
    venezPercentage: yup
      .number()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    description: yup.string().nullable(),
    isExternalBooking: yup.string().required("Ce champ est obligatoire"),
    isVisible: yup.string().required("Ce champ est obligatoire"),
    externalBookingURL: yup
      .string()
      .ensure()
      .when("isExternalBooking", {
        is: "true",
        then: () =>
          yup
            .string()
            .url("Veuillez entrer un URL valide")
            .required("Ce champ est obligatoire"),
        otherwise: () => yup.string().nullable()
      }),
    paymentMethodsInPlace: yup.boolean().nullable(),
    paymentMethodsCreditCard: yup.boolean().nullable(),
    paymentMethodsWafacash: yup.boolean().nullable(),
    ticketsDates: yup.array().of(
      yup
        .date()
        .required("Ce champ est obligatoire")
        .typeError("Ce champ est obligatoire")
        .transform((_, val) => {
          return new Date(val);
        })
    )
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch: watchFormValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hoursToLeaveOpen: "00:00"
    }
  });

  const {
    fields: eventTicketsFields,
    append: appendEventTicketsField,
    remove: removeEventTicketsField,
    update: updateEventTicketsField
  } = useFieldArray({
    control: formControl,
    name: "ticketsDates"
  });

  const createNewEventMutation = useMutation(async (data: CreateEventProps) => {
    return await apiService.MakePostRequest("events", data, token, true);
  });

  const updateEventMutation = useMutation(async (data: CreateEventProps) => {
    return await apiService.MakePutRequest(
      `events/${params?.id}`,
      data,
      token,
      true
    );
  });

  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState({
    formattedAddress: "",
    latitude: "",
    longitude: ""
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      const formFields = Object.keys(schema.fields);
      apiService.MakeGetRequest(`events/${params.id}`, token).then(response => {
        setDescription(response.description);
        setFormValue("placeId", response.place);
        setFormValue("cityId", response.city);
        setFormValue("typeId", response.type);
        setFormValue("picture", response.image);
        const paymentOptions = response.paymentOptions ?? [];
        if (paymentOptions?.includes("in_place")) {
          setFormValue("paymentMethodsInPlace", true);
        }
        if (paymentOptions?.includes("credit_card")) {
          setFormValue("paymentMethodsCreditCard", true);
        }
        if (paymentOptions?.includes("wafacash")) {
          setFormValue("paymentMethodsWafacash", true);
        }
        setImages({ image: response.image });
        Object.entries(response).forEach(entry => {
          if (formFields.indexOf(entry[0]) > -1) {
            if (entry[0] === "ticketsDates") {
              const ticketsDates = entry[1]?.map(ticketDate => {
                return toDateInputValue(
                  createZoneAwareDate(new Date(ticketDate))
                );
              });
              setFormValue("ticketsDates", ticketsDates);
            } else if (entry[0] === "date") {
              setFormValue(entry[0], toDateInputValue(entry[1]));
            } else {
              setFormValue(entry[0], entry[1]);
            }
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    const subscription = watchFormValue(value => {
      setIsExternalBooking(
        value?.isExternalBooking === true || value?.isExternalBooking === "true"
      );
    });

    return () => subscription?.unsubscribe?.();
  }, [watchFormValue]);

  const createEvent = async data => {
    try {
      setFormLoading(true);
      await createNewEventMutation.mutateAsync(data);
      toastSuccess(general.fr.message.eventCreated);
      navigate(`/events`);
      queryClient.invalidateQueries("getAllEvents");
    } catch (error) {
      console.log(error);
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateEvent = async data => {
    try {
      setFormLoading(true);
      await updateEventMutation.mutateAsync(data);
      toastSuccess(general.fr.message.eventUpdated);
      navigate(`/events`);
      queryClient.invalidateQueries("getAllEvents");
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async dataArg => {
    const data = { ...dataArg };
    try {
      data.slug = slugify(data.name);
      const city = data.cityId;
      data.typeId = data.typeId.id;
      data.cityId = city.id;
      data.placeId = data?.placeId?.id ?? null;
      const pictureFile = verifyFileList(data.picture);
      data.picture = pictureFile;
      data.description = description;
      data.latitude = city.latitude;
      data.longitude = city.longitude;
      data.facebookURL = data.facebookUrl;
      data.instagramURL = data.instagramUrl;
      data.date = createZoneAwareDate(data.date);
      const paymentOptions = [];
      if (data.paymentMethodsInPlace) {
        paymentOptions.push("in_place");
      }
      if (data.paymentMethodsCreditCard) {
        paymentOptions.push("credit_card");
      }
      if (data.paymentMethodsWafacash) {
        paymentOptions.push("wafacash");
      }
      if (!data?.venezPercentage && data?.venezPercentage !== 0) {
        data.venezPercentage = "null";
      }
      data.paymentOptions = JSON.stringify(paymentOptions);
      if (mode === FormMode.UPDATE) {
        updateEvent(data);
      } else {
        createEvent(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>Ajouter un évènement</h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="name" className="d-block">
                  Nom de l&apos;évènement{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Nom de l'évènement"
                  id="name"
                  name="name"
                  {...register("name")}
                />
                {errors.name?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.name.message}
                  </div>
                )}
              </div>

              {isAdmin && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="price" className="d-block mb-2">
                    Établissement <span className="text-md text-red">*</span>
                  </label>
                  <Controller
                    name="placeId"
                    control={formControl}
                    render={({ field }) => {
                      return (
                        <InfiniteScrollSelect
                          name="placeId"
                          id="placeId"
                          error={errors?.placeId?.message}
                          getOptionLabel={option => option?.name}
                          getOptionValue={option => option.id}
                          isOptionSelected={(option, selectedValue) => {
                            const isSelected =
                              option?.id === selectedValue?.[0]?.id;
                            return isSelected;
                          }}
                          url={{
                            path: "places"
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {errors.placeId?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.placeId.message}
                    </div>
                  )}
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="date" className="d-block">
                  Date d&apos;événement{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="date"
                  className="custom-input"
                  placeholder="Date"
                  id="date"
                  name="date"
                  {...register("date")}
                />
                {errors.date?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.date.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block mb-1">
                  Image <span className="text-md text-red">*</span>
                </label>
                <FileUploader
                  name="picture"
                  id="picture"
                  accept="image/png,image/jpeg,image/jpg"
                  {...register("picture")}
                  file={images?.image}
                  required={mode === FormMode.UPDATE ? false : true}
                />
                {errors.picture?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.picture.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="startingHour" className="d-block">
                  Heure de début <span className="text-md text-red">*</span>
                </label>
                <Controller
                  name="startingHour"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <TimeSelectInput
                        label=""
                        selectedTime={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
                {errors.startingHour?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.startingHour.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="endingHour" className="d-block">
                  Heure de clôture <span className="text-md text-red">*</span>
                </label>
                <Controller
                  name="endingHour"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <TimeSelectInput
                        label=""
                        selectedTime={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
                {errors.endingHour?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.endingHour.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="hoursToLeaveOpen" className="d-block">
                  Hours to leave open{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <Controller
                  name="hoursToLeaveOpen"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <TimeSelectInput
                        label=""
                        selectedTime={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
                {errors.hoursToLeaveOpen?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.hoursToLeaveOpen.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="isVisible" className="d-block">
                  Visible
                  <span className="text-sm text-red"> *</span>
                </label>
                <CFormSelect
                  name="isVisible"
                  id="isVisible"
                  {...register("isVisible")}
                >
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </CFormSelect>
                {errors.isVisible?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.isVisible.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="event" className="d-block mb-2">
                  Ville <span className="text-md text-red">*</span>
                </label>
                <Controller
                  name="cityId"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <InfiniteScrollSelect
                        name="cityId"
                        id="cityId"
                        isClearable={false}
                        getOptionLabel={(option: City) => option?.label}
                        getOptionValue={(option: City) => option.id}
                        isOptionSelected={(
                          option: City,
                          selectedValue: City[]
                        ) => {
                          const isSelected =
                            option?.id === selectedValue?.[0]?.id;
                          return isSelected;
                        }}
                        formatOptions={(cities: City[]) =>
                          cities.sort((a, b) =>
                            a.label.localeCompare(b.label, undefined, {
                              sensitivity: "base",
                              usage: "sort"
                            })
                          )
                        }
                        url={{
                          path: "cities"
                        }}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.cityId?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.cityId.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="address" className="d-block">
                  Adresse de l&apos;évènement
                </label>
                <CFormInput
                  type="text"
                  id="address"
                  placeholder="address"
                  name="address"
                  className="custom-input"
                  {...register("address")}
                />
                {errors.address?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.address.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="TypeId" className="d-block mb-2">
                  Type
                </label>
                <Controller
                  name="typeId"
                  control={formControl}
                  render={({ field }) => {
                    return (
                      <FormSelectAPI
                        name="typeId"
                        id="typeId"
                        isClearable={false}
                        getOptionLabel={(option: EventType) => option?.label}
                        getOptionValue={(option: EventType) => option.id}
                        isOptionSelected={(
                          option: EventType,
                          selectedValue: EventType[]
                        ) => {
                          const isSelected =
                            option?.id === selectedValue?.[0]?.id;
                          return isSelected;
                        }}
                        url={{
                          path: "events/types"
                        }}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.typeId?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.typeId.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="bookingType" className="d-block">
                  Réservation Extérieure
                </label>
                <select
                  name="bookingType"
                  id="bookingType"
                  {...register("isExternalBooking")}
                >
                  <option value="false">Non</option>
                  <option value="true">Oui</option>
                </select>
                {errors.isExternalBooking?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.isExternalBooking.message}
                  </div>
                )}
              </div>

              {isExternalBooking && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="bookingType" className="d-block">
                    URL de réservation
                  </label>
                  <CFormInput
                    type="url"
                    className="custom-input"
                    placeholder="URL de réservation"
                    id="url"
                    name="reservationUrl"
                    {...register("externalBookingURL")}
                  />
                  {errors.externalBookingURL?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.externalBookingURL.message}
                    </div>
                  )}
                </div>
              )}

              {!isExternalBooking && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="text" className="d-block mb-1">
                    Méthodes de payement
                  </label>
                  <div>
                    <CFormCheck
                      label="Carte bancaire"
                      id="paymentMethodsCreditCard"
                      {...register("paymentMethodsCreditCard")}
                    />
                    <CFormCheck
                      label="Wafacash"
                      id="paymentMethodsWafacash"
                      {...register("paymentMethodsWafacash")}
                    />
                    <CFormCheck
                      label="Sur place"
                      id="paymentMethodsInPlace"
                      {...register("paymentMethodsInPlace")}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-6 mt-4">
                <label htmlFor="email" className="d-block">
                  E-mail de l&apos;organisateur{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="email"
                  className="custom-input"
                  placeholder="email"
                  id="email"
                  name="email"
                  {...register("email")}
                />
                {errors.email?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.email.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="phone" className="d-block">
                  Téléphone de l&apos;organiseur{" "}
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="phone"
                  className="custom-input"
                  placeholder="Téléphone de l'organiseur"
                  id="phone"
                  name="phone"
                  {...register("phone")}
                />
                {errors.phone?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.phone.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="website" className="d-block">
                  Site de l&apos;événement
                </label>
                <CFormInput
                  type="url"
                  pattern="https://.*"
                  className="custom-input"
                  placeholder="Site de l'événement"
                  id="website"
                  name="website"
                  {...register("website")}
                />
                <span className="text-sm text-danger fst-italic">
                  (Exemple : https://www.exemple.com )
                </span>
                {errors.website?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.website.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="facebookUrl" className="d-block">
                  Facebook
                </label>
                <CFormInput
                  type="url"
                  pattern="https://.*"
                  className="custom-input"
                  placeholder="facebookUrl"
                  id="facebookUrl"
                  name="facebookUrl"
                  {...register("facebookUrl")}
                />
                <span className="text-sm text-danger fst-italic">
                  (Exemple : https://www.Facebook.com/exemple )
                </span>
                {errors.facebookUrl?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.facebookUrl.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="instagramUrl" className="d-block">
                  Instagram
                </label>
                <CFormInput
                  type="url"
                  pattern="https://.*"
                  className="custom-input"
                  placeholder="instagramUrl"
                  id="instagramUrl"
                  name="instagramUrl"
                  {...register("instagramUrl")}
                />
                <span className="text-sm text-danger fst-italic">
                  (Exemple : https://www.Instagram.com/exemple )
                </span>
                {errors.instagramUrl?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.instagramUrl.message}
                  </div>
                )}
              </div>

              <div className="col-md-12 mt-5">
                <label htmlFor="eventDescription" className="d-block">
                  Description de l&apos;évènement
                </label>
                <RichTextEditor
                  defaultValue={description}
                  onChange={data => {
                    setDescription(data);
                    setFormValue("description", data);
                  }}
                />
              </div>

              {!isAdmin ? null : (
                <section className="mt-5 border pt-3 rounded-sm">
                  <div className="">
                    <h5>Configuration</h5>
                  </div>
                  <div className="row form ">
                    <div className="col-sm-6 col-lg-4 my-4 ">
                      <label htmlFor="venezPercentage" className="d-block">
                        Venez Percentage
                        <span className="text-sm text-red"> *</span>
                      </label>
                      <input
                        placeholder="Venez Percentage"
                        className="custom-input"
                        type="number"
                        name="venezPercentage"
                        id="venezPercentage"
                        {...register("venezPercentage")}
                      />
                      {errors.venezPercentage?.message && (
                        <div
                          className="text-red-500 text-opacity-50"
                          style={{ color: "red" }}
                        >
                          {errors.venezPercentage.message}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              )}

              <section className="mt-5">
                <h5>Billets d'événement</h5>
              </section>
              {eventTicketsFields.map((eventTicket, index) => {
                return (
                  <div className="col-md-6 mt-4" key={eventTicket.id}>
                    <label htmlFor="ticketDate" className="d-block">
                      Date de billet
                      <span className="text-md text-red">*</span>
                    </label>
                    <div className="d-flex">
                      <CFormInput
                        type="date"
                        className="custom-input me-2"
                        placeholder="Date"
                        id="ticketDate"
                        name={`ticketsDates[${index}].date`}
                        {...register(`ticketsDates[${index}]`)}
                      />
                      <button
                        type="button"
                        className="btn btn-danger mt-2 text-white"
                        onClick={() => removeEventTicketsField(index)}
                      >
                        Supprimer
                      </button>
                    </div>
                    {errors.ticketsDates?.[index]?.message && (
                      <div
                        className="text-red-500 text-opacity-50"
                        style={{ color: "red" }}
                      >
                        {errors.ticketsDates?.[index]?.message}
                      </div>
                    )}
                  </div>
                );
              })}

              <section className="mt-5">
                <button
                  type="button"
                  className="btn btn-info green-shadow w-20 text-white"
                  onClick={() => {
                    appendEventTicketsField();
                  }}
                >
                  Ajouter un billet
                </button>
              </section>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to="/events"
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
